import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Logo1 from "../components/logo1"
import Logo1 from "../images/logo1.png"

import { Grid, Box, Button, Paper, Typography, Container, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


export default function Login() {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState('');
    const [submitname, setSubmitname] = React.useState('Log In');

    const handleChange = (event) =>{
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name==="email")
        {
          setEmail(value);
        }
        
        if(name==="password")
        {
          setPassword(value);
        }
        
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
         setOpen(false);
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        setResult('Your Username or Password you entered is incorrect');
        setEmail('');
        setPassword('');
        setOpen(true);
        setSubmitname('submit');
       
    
    
      };

    return (
  <Layout>
    <SEO title="Login" description="Login" />
    <ThemeProvider theme={theme}>
<Container>
<Box className="centercontent">
     <Grid container alignItems="center" justify="center">
       <Grid item xs={12} sm={5} md={3}>
       
           <Paper>

       <Box p={3}>
       <Box pt={3} pb={3} style={{ width:61, height:'auto', margin:'auto'}}>
       <Link to="/">   <img src={Logo1} alt="Apollonia Ventures" /> </Link>
           </Box>


           <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           {result}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>
           
           <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
      <TextValidator 
          id="email"
          name="email"
          label="Email"
          value={email}
          onChange={handleChange} 
          validators={['required', 'isEmail']}
          errorMessages={['Enter Your Email Address', 'email is not valid']}
          fullWidth
          margin="normal"
          inputProps={{ style: {fontSize:12}}}
          InputLabelProps={{ style: {fontSize:12}}}

          />

       <TextValidator 
          id="password"
          name="password"
          label="Password" 
          type="password"
          value={password}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['Enter Your Password']}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: {fontSize:12}}}
          />
<Box align="center" pt={4} pb={2}>
<Button variant="contained" color="secondary" fullWidth type="submit" style={{ backgroundColor:'rgb(198, 0, 15)', fontSize:12 }}><b>{submitname}</b>  </Button>
</Box>
<Typography variant="body2" color="textSecondary" paragraph align="center">Contact adminstrator for password issues</Typography>

          </ValidatorForm>
          </Box>

           </Paper>

       </Grid>
     </Grid>

     </Box>
     </Container>
     

    </ThemeProvider>
  </Layout>
);
}
